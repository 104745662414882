import React from "react";
import { useParams } from "react-router-dom";
import "./TextImage.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { BiSolidQuoteAltLeft } from "react-icons/bi";

function TextImage({ sections }) {
  const { title } = useParams();
  return (
    <div className="flex flex-col items-center ">
      <div className="flex flex-col lg:flex-row">
        <div className="lg:max-w-[50%]">
          <img
            src={sections[0].image}
            alt="a"
            className=" rounded-3xl lg:rounded-none lg:rounded-br-[30%]"
            style={{ maxHeight: "200vh", minWidth: "50%" }}
          />
        </div>
        <div className="mx-2 lg:ml-32 flex flex-col conta items-center lg:items-start lg:max-w-[35%] my-16">
          <h2 className="text-3xl font-bold my-5 primary-color">{title}</h2>
          <hr className="border-primary relative top-[-10px] w-6/12" />
          <div className="text-lg my-5 leading-8">{sections[0].text}</div>
        </div>
      </div>

      <div className="flex flex-col container my-20 lg:flex-row-reverse">
        <div className="ml-0 lg:ml-8">
          <img
            src={sections[1].image}
            alt="a"
            className="rounded-3xl"
            style={{ maxHeight: "200vh", minWidth: "50%" }}
          />
        </div>
        <div className="mx-4 items-center lg:items-start lg:max-w-[50%]">
          <h2 className="text-2xl font-bold my-5 ">
            {sections[1].text}
          </h2>
          <ul>
            {sections[1].items.map((item) => (
              <div className="flex flex-row items-center">
                <IoMdCheckmarkCircleOutline
                  className="primary-color"
                  size={30}
                />
                <li className="pb-5 pl-3 w-fit">{item}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col container my-20 lg:flex-row">
        <div className="ml-0 ">
          <img
            src={sections[2].image}
            alt="a"
            className="image-details-border"
            style={{ maxHeight: "200vh", minWidth: "50%" }}
          />
        </div>
        <div className="p-2 content-center lg:items-start shadow-2xl lg:max-w-[55%] lg:pl-16 primary-bg-color text-details-border text-white">
          <div>
            <h2 className="md:text-3xl text-xl font-normal leading-9">
            <span className="inline-block pr-3 italic pm-2"><BiSolidQuoteAltLeft className="text-white" size={35} /></span>
              {sections[2].text}
            <span className="inline-block pl-3 italic pm-2"><BiSolidQuoteAltRight className="text-white" size={35} /></span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextImage;
