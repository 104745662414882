export const RouteMap = [
  /* {
    title: "White&Bright Cleaning",
    url: '/',
  },
  {
    title: "Zakelijk",
    url: '/Zakelijk',
  },
  {
    title: "Particulier",
    url: '/Particulier',
  }, */
  {
    title: "Gebouw",
    url: "/zakelijk/Gebouw",
  },
  {
    title: "kantoren",
    url: "/zakelijk/Kantoren",
  },
  {
    title: "Vakantiehuizen",
    url: "/zakelijk/Vakantiehuizen",
  },
  {
    title: "Huishoudelijk",
    url: "/particulier/Huishoudelijk",
  },
  {
    title: "Verhuizing",
    url: "/particulier/Verhuizing",
  },
  {
    title: "Appartementen",
    url: "/particulier/Appartementen",
  },
  /* {
    title: "Vacatures",
    url: "/Vacatures",
  },
  {
    title: "Contact",
    url: "/contact-us",
  }, */
];
