import React from "react";
import "./top.css";
const Aboutus = () => {
  return (
    <div className="About-us mt-12">
      <div className="flex mx-[2%] xl:mx-[25%] md:mx-[10%] flex-col md:flex-row ">
        <div className="py-10 md:w-9/12">
          <h4 className="text-3xl text-[#00b0f0] my-4 text-center ">
            Wie Zijn We ?
          </h4>
          <p className="text-[#666666] text-sm font-light pr-0 md:pr-7 leading-8 text-center ">
            White&Bright Cleaning is een schoonmaakbedrijf dat een totaalpakket aan
            schoonmaakdiensten biedt aan zowel zakelijke als particuliere
            klanten.
            <br />
            Onze schoonmaakservice beslaat alles van interieurverzorging tot aan
            specialistische reiniging en sanitair reiniging. Wij leveren aan
            bedrijven, instellingen en particulieren een breed pakket aan
            diensten. White&Bright Cleaning is gekenmerkt door de uitstekende kwaliteit
            die wij leveren. <br />
            <br />
            Meer weten over White&Bright Cleaning als schoonmaakbedrijf? Lees dan snel
            verder.
          </p>
        </div>
        <div className="bg-[#eeeeee] py-10 px-4  rounded-2xl shadow-md text-center md:w-11/12">
          <h4 className="text-3xl text-[#00b0f0] my-4">
            {" "}
            Waarom voor ons kiezen?
          </h4>
          <p className="text-[#666666] text-sm font-light leading-8">
            Als White&Bright Cleaning zijn we meer dan een schoonmaakbedrijf. Onze kern
            blijft het reinigen van elke denkbare ruimte maar wij bieden veel
            meer dan dat. Bij onze schoonmaakservice staat de wens van de klant
            ten alle tijden centraal. Dankzij onze goede service en de ervaring
            en deskundigheid van ons personeel, zal ervoor zorgen dat u altijd
            tevreden over ons zal zijn. Als schoonmaakbedrijf onderscheiden we
            ons door de volgende punten: <br />
            Klant ontzorgen.<br />
            Ervaren, enthousiast en specialistisch personeel.<br />
            Deskundig in verschillende vakgebieden.<br />
            Altijd en overal bereikbaar.<br />
            Een team van experts op stand-by.<br />
            Hand-on mentaliteit.
          </p>
        </div>
      </div>
      <div>
        {/* <button className="circle-button">
          <HiOutlineArrowUp className="arrow" />
        </button> */}
      </div>
    </div>
  );
};

export default Aboutus;
