import React from "react";
import "./FeatureList.css";
import { Link } from "react-router-dom";

function FeatureList({ data }) {
  return (
    <>
      <div className="flex flex-wrap gap-y-10 justify-center mt-10">
        {data.items.map((item) => (
          <div key={item.id} className="basis-full lg:basis-6/12">
            <Link to={`/${data.sectionName}/${item.title}`}>
              <div className="main-zakelijk overflow-hidden relative max-w-[90%] text-center cursor-pointer shadow-xl mx-auto">
                <div
                  className="zakelijk relative max-w-full custom-rounded-xl h-[50vh] shadow-lg"
                  style={{ backgroundImage: `url("${item.image}")` }}
                ></div>
                <div className="back rounded-xl"></div>
              </div>
                <div className="bg-primary text-white py-3 overflow-hidden max-w-[90%] text-center cursor-pointer shadow-xl mx-auto">
                  {item.title}
                </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default FeatureList;
