import React, { useState } from "react";
import "./Contact.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";
import emailjsConfig from "../../email-js.config";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    subject: "",
    objectLink: "",
    message: "",
    attachment: null,
  });
  const [copiedText, setCopiedText] = useState(null);

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedText(textToCopy); // Update state to indicate text has been copied
        setTimeout(() => setCopiedText(null), 1500); // Reset copiedText state after 1.5 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Check if required fields are filled
    if (
      !formData.name ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      console.error("Please fill in all required fields.");
      return;
    }

    const jsonData = {
      to_name: "White&bright",
      from_name: formData.name,
      to_email: emailjsConfig.userID,
      from_email: formData.email,
      subject: formData.subject,
      phone_number: formData.phoneNumber || "",
      message: formData.message,
      object_link: formData.objectLink || "",
    };
    console.log(formData);
    console.log(jsonData);

    emailjs
      .send(
        emailjsConfig.serviceID,
        emailjsConfig.templateID,
        jsonData,
        emailjsConfig.userID
      )
      .then((response) => {
        console.log("Email sent successfully!", response);
        // Clear form fields after sending
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          subject: "",
          objectLink: "",
          message: "",
          attachment: null,
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };
  return (
    <>
      <div className="page-header ">
        <div className="container-contact">
          <div className="py-24">
            <div className="w-full pb-2.5">
              <div className="text-5xl text-gray-100">
                <h1 className="font-bold inline-block">Contact</h1>
                <span className="px-4 inline-block">-</span>
                <p className="text-gray-400 inline-block text-5xl font-light">
                  Kom in contact met ons
                </p>
              </div>
            </div>
            <hr />
            <div>
              <span className="text-gray-200 font-light text-base arrow">
                <Link to="/" className="relative pr-10">
                  {" "}
                  Home
                </Link>
                {/* <a href="/" className="relative pr-10">
                  Home
                </a> */}
                Contact
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="py-24">
        <div className="lg:mx-16 md:mx-16 mx-7">
          <div className="flex flex-wrap xl:gap-18 lg:gap-10 gap-0 justify-center">
            <div className="card lg:basis-1/4 md:basis-1/2 basis-full rounded-md">
              <ul>
                <li className="icon">
                  <FaPhoneAlt />
                </li>
                <li className="header">Bel ons</li>
                {/* <li>
                  <a className="num-one" href="/">
                    064 504 2700
                  </a>
                </li> */}

                <li className="num-one my-4 leading-7 text-xl font-light h-16">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCopy("+31 645042700");
                    }}
                  >
                    +31 645042700
                  </a>
                </li>
                <li className="footer-card">Wij zijn altijd bereikbaar.</li>
              </ul>
            </div>
            <div className="card lg:basis-1/6 md:basis-1/2 basis-full rounded-md">
              <ul>
                <li className="icon">
                  <IoMdMail />
                </li>
                <li className="header">E-Mail</li>
                <li className="num-one my-4 leading-7 text-xl font-light h-16">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCopy("Info@white-bright.nl");
                    }}
                  >
                    Info@white-bright.nl
                  </a>
                </li>

                <li className="footer-card">Reactie binnen 24 uur.</li>
              </ul>
            </div>
            <div className="card lg:basis-1/6 md:basis-1/2 basis-full rounded-md">
              <ul>
                <li className="icon">
                  <IoLocationSharp />
                </li>
                <li className="header">Adres</li>
                <li className="num-one font-light text-xl my-4 leading-7 h-16">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCopy(
                        "Steenplaetsstraat 6, Unit 4.14 2288AA Rijswijk"
                      );
                    }}
                  >
                    <div className="mx-5">
                      Steenplaetsstraat 6, Unit 4.14 2288AA Rijswijk
                    </div>
                  </a>
                </li>

                <li className="footer-card">Zie Google Maps.</li>
              </ul>
            </div>
            <div className="card lg:basis-1/6 md:basis-1/2 basis-full rounded-md">
              <ul>
                <li className="icon">
                  <FaRegClock />
                </li>
                <li className="header">24/7 gaan we door</li>

                <li className="my-4 leading-7 text-xl font-light h-16">
                  <span>
                    We hebben de snelste
                    <br /> service voor u in huis
                  </span>
                </li>
                <li className="footer-card text-center">Contact ons gerust.</li>
              </ul>
            </div>
          </div>

          <form onSubmit={sendEmail}>
            <div className="inform-mail flex lg:flex-row flex-col ">
              <div className="border lg:basis-3/12 basis-full">
                <h2>
                  Wij zijn 24/7 <hr /> Bereikbaar
                </h2>
              </div>

              <div className="inform-enter lg:basis-4/12 basis-full">
                <input
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  aria-invalid="false"
                  required
                  placeholder="Naam"
                />
                <input
                  name="phoneNumber"
                  type="text"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  aria-invalid="false"
                  required
                  placeholder="phone-number"
                />
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  aria-invalid="false"
                  required
                  placeholder="E-mailadre"
                />
                <input
                  name="subject"
                  type="text"
                  value={formData.subject}
                  onChange={handleChange}
                  aria-invalid="false"
                  required
                  placeholder="Subject"
                />
                <input
                  name="objectLink"
                  type="text"
                  value={formData.objectLink}
                  onChange={handleChange}
                  aria-invalid="false"
                  placeholder="Optioneel : Link naar object"
                />
              </div>

              <div className="message lg:basis-5/12 basis-full">
                <textarea
                  className="w-full"
                  aria-invalid="false"
                  placeholder="Bericht"
                  value={formData.message}
                  onChange={handleChange}
                  name="message"
                ></textarea>
                <input
                  type="file"
                  name="attachment"
                  onChange={(e) =>
                    setFormData({ ...formData, attachment: e.target.files[0] })
                  }
                />
              </div>
            </div>
            <div>
              <input
                className="btn float-right rounded "
                type="submit"
                value="Verzenden"
              ></input>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
