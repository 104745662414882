import React from 'react'
import './SlideOfLogo.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import referenties1 from "../assests/referenties1.webp"
import referenties2 from "../assests/referenties2.webp"
import referenties3 from "../assests/referenties3.webp"
import referenties4 from "../assests/referenties4.webp"
import referenties5 from "../assests/referenties5.webp"
import referenties6 from "../assests/referenties6.webp"
function SlideOfLogo() {
    const slideImages = [
        { id: 1, url: referenties1, alt: 'ref1' },
        { id: 2, url: referenties2, alt: 'ref2' },
        { id: 3, url: referenties3, alt: 'ref3' },
        { id: 4, url: referenties4, alt: 'ref4' },
        { id: 5, url: referenties5, alt: 'ref5' },
        { id: 5, url: referenties6, alt: 'ref6' },
    ];


    const responsiveSettings = [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ];



    return (
        <div className="slide-container  py-28">
            <Slide lidesToScroll={ 1 } slidesToShow={ 2 } arrows={ false } duration={ 1500 } transitionDuration={ 500 } responsive={responsiveSettings}>
                { slideImages.map( ( slide ) => (
                    <div key={ slide.id } className="each-slide h-[150px] md:h-[250px]">
                        <div className="slide-content">
                            <img src={ slide.url } alt={ slide.alt } className="slide-image" />
                        </div>
                    </div>
                ) ) }
            </Slide>
        </div>
    )
}

export default SlideOfLogo