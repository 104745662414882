const VactoresData = [
  {
    id: "Vacatures_01",
    title: "Schoonmaakmedewerker zuid holland",
    title_2: "Solliciteer direct wanneer jij onderstaande kunt afvinken:",
    data_1: `kom werken bij white en bright  cleaning! `,
    data_2: `Ook welkom op ZZP basis
      Wij zoeken schoonmaak professionals met aandacht voor hun werk.`,
    listData: [
      'Ervaring als schoonmaaker',
      'In het bezit van rijbewijs één auto',
      'Fysiek fit',
      'Positieve mindset'
    ]
  },
  {
    id: "Vacatures_02",
    title: "Schoonmaakmedewerker schiedam ",
    houre: "9uur",
    located: "schiedam",
    title_1: "Functieomschrijving:",
    title_2: "Taken en verantwoordelijkheden:",
    title_3: "Aanbod",
    title_4: "Werkgebied: schiedam",
    data_1: `Voor een locatie in schiedam zijn wij op zoek naar een enthousiaste en gemotiveerde schoonmaker!
      Het gaat om een leuke baan  maandag woensdag en vrijdag van 17:00 – 20:00 (3 uur per dag).
      Om succesvol te zijn in deze functie ben je gastvrij, servicegericht en representatief.`,
    data_2: "het uitvoeren van schoonmaakwerkzaamheden",
    data_3: `Het gaat om een dynamische en afwisselende baan in een prettige werkomgeving. Je kunt rekenen op goede arbeidsvoorwaarden volgens cao.`,
    data_4: "Ben je enthousiast geworden bij het lezen van deze vacature? Solliciteer direct via de sollicitatiebutton of door je ",
  },
  {
    id: "Vacatures_03",
    title: "Schoonmaakmedewerker den haag",
    houre: "8uur",
    located: "den haag",
    title_1: "Functieomschrijving:",
    title_2: "Taken en verantwoordelijkheden:",
    title_3: "Aanbod",
    title_4: "Werkgebied: den haag",
    data_1: `Voor een locatie in den haag zijn wij op zoek naar een enthousiaste en gemotiveerde schoonmaker!
      Het gaat om een leuke baan  maandag  en vrijdag van 17:00 – 21:00 (4 uur per dag).
      Om succesvol te zijn in deze functie ben je gastvrij, servicegericht en representatief.`,
    data_2: "het uitvoeren van schoonmaakwerkzaamheden",
    data_3: `Het gaat om een dynamische en afwisselende baan in een prettige werkomgeving. Je kunt rekenen op goede arbeidsvoorwaarden volgens cao.`,
    data_4: "Ben je enthousiast geworden bij het lezen van deze vacature? Solliciteer direct via de sollicitatiebutton of door je ",
  },
];

export default VactoresData;
