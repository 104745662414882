import React, { useState } from "react";
// import {MovingComponent} from "react-moving-text";
import "./Home.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import home_1 from "../../components/assests/home 1.webp";
import home_2 from "../../components/assests/home 2.webp";
import home_3 from "../../components/assests/home 3.webp";
import home_4 from "../../components/assests/home 4.webp";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Aboutus from "../Aboutus/Aboutus";
import SlideOfLogo from "../../components/References/SlideOfLogo";

const Slidephoto = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  // Function to handle showing the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };
  const home_text = {
    head_caption: `White&Bright Cleaning`,
    second_caption: "zijn trots dat wij u als klant te mogen hebben.",
    primary_caption: "Wij geven hoogwaardig dienstverlening en",
    primary_caption_2: "wij zijn toegewijd om professioneel",
    p_caption: "de beste klantenservice aan te bieden",
  };
  const slides = [
    {
      id: 0,
      url: home_1,
    },
    {
      id: 1,
      url: home_2,
    },
    {
      id: 2,
      url: home_3,
    },
    {
      id: 3,
      url: home_4,
    },
  ];

  const properties = {
    prevArrow: (
      <button className="arrbtn1">
        <img
          alt=""
          onClick={prevSlide}
          className="arrimg w-[200px] h-[100px]"
          src={
            currentIndex !== 0
              ? slides[currentIndex - 1].url
              : slides[currentIndex + 3].url
          }
        />
        <IoIosArrowBack className="arrBack text-3xl h-[100%]" />
      </button>
    ),
    nextArrow: (
      <button className="arrbtn2">
        {" "}
        <IoIosArrowForward className="arrBack text-3xl h-[100%]" />
        <img
          alt=""
          onClick={nextSlide}
          className="arrimg2 w-[200px] h-[100px]"
          src={
            currentIndex !== 3
              ? slides[currentIndex + 1].url
              : slides[currentIndex - 3].url
          }
        />
      </button>
    ),
  };

  return (
    <div className="Home">
      <div className="relative">
        <Slide {...properties} autoplay={true}>
          {slides.map((image, index) => (
            <>
              <div key={index}>
                <img src={image.url} alt="/" className="slidmain" />
              </div>
            </>
          ))}
        </Slide>
        <div className="absolute home-text md:w-4/6 w-9/12 ml-12 z-10">
          <div className="md:text-4xl text-lg secondary-color pb-6 font-light font-semibold home-text-shadow">
            {home_text.head_caption} {home_text.second_caption}
          </div>
          {/* <div className="text-2xl text-white pb-4">
          
        </div> */}
          <div className="md:text-2xl text-md text-white home-text-shadow">
            {home_text.primary_caption} {home_text.primary_caption_2}
          </div>
          <div className="md:text-2xl text-md text-white home-text-shadow">
            {home_text.p_caption}
          </div>
        </div>
        <div id="overlay"></div>
      </div>
      <Aboutus />
      <SlideOfLogo />
    </div>
  );
};

export default Slidephoto;
