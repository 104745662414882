import Kantoren from "../../components/assests/kantoren 1.webp";
import Kantoren2 from "../../components/assests/kantoren 2.webp";
import Kantoren3 from "../../components/assests/kantoren 3.webp";
import Gebouw from "../../components/assests/gebouw 1.webp";
import Gebouw2 from "../../components/assests/gebouw 2.webp";
import Gebouw3 from "../../components/assests/gebouw 3.webp";
import Vakantiehuizen from "../../components/assests/vakantiehuizen, 1.webp";
import Vakantiehuizen2 from "../../components/assests/vakantiehuizen, 2.webp";
import Vakantiehuizen3 from "../../components/assests/vakantiehuizen, 3.webp";
import Hiuishuidlijk from "../../components/assests/hiuishuidlijk 1.webp";
import Hiuishuidlijk2 from "../../components/assests/hiuishuidlijk 2.webp";
import Hiuishuidlijk3 from "../../components/assests/hiuishuidlijk 3.webp";
import Verhuizing from "../../components/assests/verhuizing 1.webp";
import Verhuizing2 from "../../components/assests/verhuizing 2.webp";
import Verhuizing3 from "../../components/assests/verhuizing 3.webp";
import Appartementen from "../../components/assests/appartementen 1.webp";
import Appartementen2 from "../../components/assests/appartementen 2.webp";
import Appartementen3 from "../../components/assests/appartementen 3.webp";

export const zakelijk = {
  sectionName: "zakelijk",
  items: [
    {
      id: 1,
      title: "Kantoren",
      image: Kantoren,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning bieden we gespecialiseerde schoonmaakdiensten voor kantoren en commerciële gebouwen,
          met de nadruk op het bieden van een schone en stimulerende werkomgeving.
          We realiseren ons het belang van de eerste indruk en de impact ervan op klanten en medewerkers,
          daarom houden we ons aan de hoogste normen van netheid en professionaliteit.`,
          image: Kantoren,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Dagelijkse of periodieke reiniging van openbare ruimtes, kantoren en vergaderzalen.",
            "Het desinfecteren en steriliseren van vaak aangeraakte oppervlakken om ieders veiligheid te garanderen.",
            "Grondige reiniging van vitale gebieden zoals lobby's, liften en toiletten.",
            "Een professioneel en goed uitgerust team met de nieuwste technologieën en milieuvriendelijke schoonmaakmaterialen.",
          ],
          image: Kantoren2,
        },
        {
          sub_id: 2,
          text: `Met White&Bright Cleaning kunt u er zeker van zijn dat uw werkplek altijd in topconditie zal zijn,
          wat de productiviteit verhoogt en een uitstekend beeld van uw bedrijf weerspiegelt`,
          image: Kantoren3,
        },
      ],
    },
    {
      id: 2,
      title: "Gebouw",
      image: Gebouw,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning begrijpen we het belang van netheid en orde overal, daarom bieden we uitstekende schoonmaakdiensten die aan al uw behoeften voldoen.
          We zorgen voor elke hoek van uw gebouwen, met een focus op de ingang van het gebouw
          , trappen en de liften die de eerste indruk geven aan bezoekers en bewoners, gelovend dat de eerste indruk blijvend is.`,
          image: Gebouw,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Het reinigen van de binnenkant van gebouwen om hun schoonheid en glans te benadrukke.",
            "Het zorgvuldig reinigen van trappen en ingangen om een uitnodigende en veilige omgeving te garandere.",
            "Het gebruik van milieuvriendelijke schoonmaakproducten die uw gezondheid en het milieu bescherme.",
            "Een getraind team uitgerust met de nieuwste gereedschappen om de beste service te bieden.",
          ],
          image: Gebouw2,
        },
        {
          sub_id: 3,
          text: `Bij White&Bright Cleaning beloven we u een ongeëvenaarde schoonmaakervaring die gemoedsrust en tevredenheid garandeert.`,
          image: Gebouw3,
        },
      ],
    },
    {
      id: 3,
      title: "Vakantiehuizen",
      image: Vakantiehuizen,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning bieden we ideale schoonmaakdiensten voor vakantiehuizen,
          wat u een zorgeloze vakantie garandeert. We weten dat uw vakantiehuis uw toevluchtsoord is voor rust en ontspanning,
          en we zorgen ervoor dat het in de beste staat is bij uw aankomst.`,
          image: Vakantiehuizen,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Grondige reiniging van alle kamers, buitenruimtes en recreatieve voorzieningen.",
            "Het klaarmaken van het huis voor uw aankomst, inclusief het vervangen van beddengoed en het organiseren van meubels.",
            "Het gebruik van hoogwaardige schoonmaakproducten om een schone en frisse omgeving te waarborgen.",
            "Een dienst na verblijf om ervoor te zorgen dat uw huis schoon en klaar blijft voor uw volgende bezoek.",
          ],
          image: Vakantiehuizen2,
        },
        {
          sub_id: 3,
          text: `Met White&Bright Cleaning kunt u er zeker van zijn dat uw vakantiehuis een paradijs van netheid en comfort zal zijn.`,
          image: Vakantiehuizen3,
        },
      ],
    },
  ],
};

export const particulier = {
  sectionName: "particulier",
  items: [
    {
      id: 1,
      title: "Huishoudelijk",
      image: Hiuishuidlijk,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning realiseren we ons dat het huis van een familie hun eigen koninkrijk is, en we zijn hier om ervoor te zorgen dat het schoon en uitnodigend blijft.
          Onze diensten zijn ontworpen om een gezonde en comfortabele omgeving te bieden voor alle gezinsleden.`,
          image: Hiuishuidlijk,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Nauwkeurige reiniging van alle slaapkamers, woonkamers en gemeenschappelijke ruimtes, met respect voor uw privacy.",
            "Het desinfecteren van badkamers en keukens met kind- en huisdiervriendelijke materialen.",
            "Het praktisch en aantrekkelijk organiseren van speelgoed, boeken en persoonlijke spullen.",
            "Een professioneel team dat elk detail van uw huis met zorg en respect behandelt.",
          ],
          image: Hiuishuidlijk2,
        },
        {
          sub_id: 3,
          text: `Met White&Bright Cleaning kunt u genieten van uw familietijd in een huis dat straalt van netheid en orde.`,
          image: Hiuishuidlijk3,
        },
      ],
    },
    {
      id: 2,
      title: "Verhuizing",
      image: Verhuizing,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning bieden we uitgebreide schoonmaakdiensten voor huizen voor en na verhuizing,
          wat u een schone en zorgeloze nieuwe start garandeert. Of u nu uw oude huis verlaat of zich voorbereidt om een nieuw huis te verwelkomen,
          ons team staat klaar om een vlekkeloze schoonmaakdienst te bieden.`,
          image: Verhuizing,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Diepe reiniging van elk deel van het huis, inclusief vloeren, muren en oppervlakken.",
            "Het verwijderen van opgehoopt stof en vuil om een gezonde omgeving te waarborgen.",
            "Het polijsten en desinfecteren van badkamers en keukens om ze direct gebruiksklaar te maken.",
            "Een professioneel team dat de beste apparatuur en veilige schoonmaakmaterialen gebruikt.",
          ],
          image: Verhuizing2,
        },
        {
          sub_id: 3,
          text: `Met White&Bright Cleaning kunt u er zeker van zijn dat uw huis er op zijn best uitziet, klaar om u te verwelkomen of afscheid te nemen in de beste staat.`,
          image: Verhuizing3,
        },
      ],
    },
    {
      id: 3,
      title: "Appartementen",
      image: Appartementen,
      sections: [
        {
          sub_id: 1,
          text: `Bij White&Bright Cleaning begrijpen we het belang van leven in een schone en geordende omgeving,
          vooral in kleine appartementen waar elke ruimte waardevol is.
          Daarom bieden we nauwkeurige en gespecialiseerde schoonmaakdiensten die ervoor zorgen dat u optimaal gebruik maakt van uw persoonlijke ruimte.`,
          image: Appartementen,
        },
        {
          sub_id: 2,
          text: `We bieden:`,
          items: [
            "Grondige reiniging die elke hoek en kier omvat, met aandacht voor de kleine details.",
            "Het organiseren van de ruimte om een comfortabele en praktische leefomgeving te creëren.",
            "Het gebruik van effectieve en veilige schoonmaakproducten om uw gezondheid en comfort te waarborgen.",
          ],
          image: Appartementen2,
        },
        {
          sub_id: 3,
          text: `Met White&Bright Cleaning kunt u er zeker van zijn dat u terugkeert naar een huis dat straalt van netheid en frisheid, wat u de perfecte ruimte biedt om te ontspannen en gelukkig te leven.`,
          image: Appartementen3,
        },
      ],
    },
  ],
};
