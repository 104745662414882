import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { MdClear, MdOutlineMenu } from "react-icons/md";
import logo from "../../components/assests/logo.webp";
import { FaAngleDown } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { theme } from "@material-tailwind/react";
const navListMenuItems = [
  {
    title: "Huishoudelijk",
    goTo: "/particulier/Huishoudelijk",
  },
  {
    title: "Verhuizing",
    goTo: "/particulier/Verhuizing",
  },
  {
    title: "Appartementen",
    goTo: "/particulier/Appartementen",
  },
];
const navListMenuItems2 = [
  {
    title: "Gebouw",
    goTo: "/zakelijk/Gebouw",
  },
  {
    title: "Kantoren",
    goTo: "/zakelijk/Kantoren",
  },
  {
    title: "Vakantiehuizen",
    goTo: "/zakelijk/Vakantiehuizen",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const location = useLocation();
  const { pathname } = location;
  const renderItems = navListMenuItems2.map(({ title, goTo }, key) => (
    <Link to={goTo} key={key}>
      <MenuItem className="flex items-center gap-3 rounded-lg">
        <div>
          <Typography
            variant="h6"
            color="blue-gray"
            className="flex items-center text-sm font-bold"
          >
            {title}
          </Typography>
        </div>
      </MenuItem>
    </Link>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="h6" className="font-medium">
            <Link to="/Zakelijk">
              <ListItem
                className={`flex items-center gap-2 py-4 pr-4 font-medium text-gray-900 ${
                  isMenuOpen
                    ? "bg-[#00B0F0] text-white"
                    : "bg-transparent text-gray-900"
                }  ${
                  pathname === "/Zakelijk" ? "bg-[#00B0F0] text-white" : ""
                }  hover:bg-[#00B0F0] focus:bg-[#00B0F0]  hover:text-white focus:text-white`}
                selected={isMenuOpen || isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
              >
                Zakelijk
                <FaAngleDown
                  strokeWidth={2.5}
                  className={`hidden h-3 w-3 transition-transform lg:block ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
                <FaAngleDown
                  strokeWidth={2.5}
                  className={`block h-3 w-3 transition-transform lg:hidden ${
                    isMobileMenuOpen ? "rotate-180" : ""
                  }`}
                />
              </ListItem>
            </Link>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden rounded-xl lg:block">
          <ul className="outline-none outline-0">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavListMenu2() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const renderItems = navListMenuItems.map(({ icon, title, goTo }, key) => (
    <Link to={goTo} key={key}>
      <MenuItem className="flex flex-col gap-3 rounded-lg">
        <Typography
          variant="h6"
          color="blue-gray"
          className="flex items-center text-sm font-bold"
        >
          {title}
        </Typography>
      </MenuItem>
    </Link>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="h6" className="font-medium">
            <Link to="/particulier">
              <ListItem
                className={`flex items-center gap-2 py-4 pr-4 font-medium text-gray-900 ${
                  isMenuOpen
                    ? "bg-[#00B0F0] text-white"
                    : "bg-transparent text-gray-900"
                }  ${
                  pathname === "/particulier" ? "bg-[#00B0F0] text-white" : ""
                }  hover:bg-[#00B0F0] focus:bg-[#00B0F0]  hover:text-white focus:text-white`}
                selected={isMenuOpen || isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
              >
                Particulier
                <FaAngleDown
                  strokeWidth={2.5}
                  className={`hidden h-3 w-3 transition-transform lg:block ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
                <FaAngleDown
                  strokeWidth={2.5}
                  className={`block h-3 w-3 transition-transform lg:hidden ${
                    isMobileMenuOpen ? "rotate-180" : ""
                  }`}
                />
              </ListItem>
            </Link>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden rounded-xl lg:block">
          <ul className="outline-none outline-0">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as="a"
        href="#"
        variant="h6"
        color="blue-gray"
        className="font-medium"
      >
        <Link to="/">
          <ListItem
            className={`flex items-center gap-2 py-4 pr-4  ${
              pathname === "/" ? "bg-[#00B0F0] text-white" : "bg-transparent"
            } hover:bg-[#00B0F0] focus:bg-[#00B0F0]  hover:text-white focus:text-white`}
          >
            Home
          </ListItem>
        </Link>
      </Typography>
      <NavListMenu />
      <NavListMenu2 />
      <Typography
        as="a"
        href="#"
        variant="h6"
        color="blue-gray"
        className="font-medium"
      >
        <Link to="/Vacatures">
          <ListItem
            className={`flex items-center gap-2 py-4 pr-4  ${
              pathname === "/Vacatures"
                ? "bg-[#00B0F0] text-white"
                : "bg-transparent"
            } hover:bg-[#00B0F0] focus:bg-[#00B0F0]  hover:text-white focus:text-white`}
          >
            Vacatures
          </ListItem>
        </Link>
      </Typography>
      <Typography
        as="a"
        href="#"
        variant="h6"
        color="blue-gray"
        className="font-medium"
      >
        <Link to="/contact-us">
          <ListItem
            className={`flex items-center gap-2 py-4 pr-4  ${
              pathname === "/contact-us"
                ? "bg-[#00B0F0] text-white"
                : "bg-transparent"
            } hover:bg-[#00B0F0] focus:bg-[#00B0F0]  hover:text-white focus:text-white`}
          >
            Contact
          </ListItem>
        </Link>
      </Typography>
    </List>
  );
}

export default function Navinformation() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto px-4 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Link to="/">
          <img
            src={logo}
            alt=""
            style={{
              height: "80px",
              minWidth: "160px",
            }}
          />
        </Link>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <MdClear className="h-6 w-6" />
          ) : (
            <MdOutlineMenu className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
