import React from "react";
import "./DescPage.css";
import VacturesData from "../../Data/Vacatures/Vacatures";
import { Link, useParams } from "react-router-dom";
import { MdOutlineCheckCircle } from "react-icons/md";
import "./DescPage.css";

const DescPage = () => {
  const { id } = useParams();
  const selectedVacture = VacturesData.find((vacture) => vacture.id === id);
  const firstVacture = id === "Vacatures_01" ? true : false;

  if (!selectedVacture) {
    return <div>Vacature niet gevonden</div>;
  }
  return (
    <>
      <div className="desc-page w-full">
        <div className="py-8 mx-12 text-white">
          {/* <button>
            <a href="/">Terug naar het overzicht</a>
          </button> */}
          <h2 className="xl:text-4xl lg:text-2xl text-lg font-bold my-5">
            {selectedVacture.title}
          </h2>
          <hr />
        </div>
      </div>
      <div className="main-desc lg:w-7/12 w-full md:mx-12 mx-0 py-8 px-4">
        {!firstVacture && (
          <>
            <h2 className="py-4">{selectedVacture.title_1}</h2>
            <p>{selectedVacture.data_1}</p>
            <h2 className="py-4">{selectedVacture.title_2}</h2>
            <p>{selectedVacture.data_2}</p>
            <h2 className="py-4">{selectedVacture.title_3}</h2>
            <p>{selectedVacture.data_3}</p>
            <h2 className="py-4">{selectedVacture.title_4}</h2>
            <p>{selectedVacture.data_4}</p>
          </>
        )}
        {firstVacture && (
          <>
            <h1>{selectedVacture.data_1}</h1>
            <h1>{selectedVacture.data_2}</h1>
            <h2 className="py-4">{selectedVacture.title_2}</h2>
            <ul>
              {selectedVacture.listData.map((item) => (
                <div className="flex items-center">
                  <MdOutlineCheckCircle className="primary-color" />
                  <li className="my-2 ml-3 ">{item}</li>
                </div>
              ))}
            </ul>
          </>
        )}
      </div>
      <div>
        <Link to="/contact-us">
          <input
            className="btn-vac float-right rounded mr-12"
            type="submit"
            value="Solliciteren"
          ></input>
        </Link>
      </div>
    </>
  );
};

export default DescPage;
